// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-blog-a-short-guide-to-help-you-pick-the-correct-html-tag-mdx": () => import("./../../../src/blog/a-short-guide-to-help-you-pick-the-correct-html-tag.mdx" /* webpackChunkName: "component---src-blog-a-short-guide-to-help-you-pick-the-correct-html-tag-mdx" */),
  "component---src-blog-introduction-mdx": () => import("./../../../src/blog/introduction.mdx" /* webpackChunkName: "component---src-blog-introduction-mdx" */),
  "component---src-blog-the-7th-heading-mdx": () => import("./../../../src/blog/the-7th-heading.mdx" /* webpackChunkName: "component---src-blog-the-7th-heading-mdx" */),
  "component---src-blog-the-effect-of-css-on-screen-readers-mdx": () => import("./../../../src/blog/the-effect-of-css-on-screen-readers.mdx" /* webpackChunkName: "component---src-blog-the-effect-of-css-on-screen-readers-mdx" */),
  "component---src-blog-use-alt-attribute-properly-for-accessibility-and-seo-mdx": () => import("./../../../src/blog/use-alt-attribute-properly-for-accessibility-and-seo.mdx" /* webpackChunkName: "component---src-blog-use-alt-attribute-properly-for-accessibility-and-seo-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-read-js": () => import("./../../../src/pages/read.js" /* webpackChunkName: "component---src-pages-read-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

